import { AxiosResponse } from 'axios';
import { instance } from './index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProjectType } from '../types/projects';

const controller = new AbortController();

export const postProject = createAsyncThunk(
  'projects/createProject',
  async (data: ProjectType, { signal }) => {
    signal.addEventListener('abort', () => {
      controller.abort();
    });
      const response: AxiosResponse = await instance.post('/projects', data);
      return response.data;
  }
);

export const getProject = createAsyncThunk(
  'projects/getProject',
  async (_data, { signal }) => {
    signal.addEventListener('abort', () => {
      controller.abort();
    });
      const response: AxiosResponse = await instance.get('/projects');
      return response.data;
  }
);
