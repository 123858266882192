import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form"
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {  useNavigate } from 'react-router-dom';
import { postProject } from '../api/projects';
import { AppDispatch } from '../store';
import { putUser } from '../api/users';
import Logo from '../components/fields/Logo';

const CreateAccountPage2 = () => {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	
	const schema = yup
		.object({
			firstName: yup.string().required("First name is required"),
			lastName: yup.string().required("Last name is required"),
			projectName: yup.string().required("Project name is required"),
		})
		.required();

	type Inputs = {
		firstName: string,
		lastName: string,
		projectName: string,
	}

	const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm<Inputs>({
		resolver: yupResolver(schema),
		defaultValues: {
			firstName: '',
			lastName: '',
			projectName: '',
		},
		mode: 'onBlur',
	});

	const onSubmit = (data: Inputs) => {
		const {firstName, lastName, projectName} = data;
		dispatch(putUser({firstName, lastName}))
			.then((res) => {
				if (res.payload.id) {
					dispatch(postProject({name: projectName}))
						.then((res) => {
							if (res.payload.id) {
								navigate('/dashboard');
							}
						})
						.catch((err) => {
							console.log(err, 'err')
						});
				}
			})
			.catch((err) => {
				console.log(err, 'err')
			});
	}

	return (
		<div className="bg-white min-h-screen flex flex-col items-center justify-start font-['Inter'] text-custom-900 p-4">
			<div className="text-2xl font-bold mt-20 p-5">
				<Logo />
			</div>
			<div className="text-3xl font-bold mt-20">Tell us about yourself</div>
			<div className="mt-10 w-full max-w-md">
				<form onSubmit={handleSubmit(onSubmit)}>
					<label htmlFor="firstName" className="text-custom-900 text-sm">First Name:</label>
					<input
						id='firstName'
						type="text"
						placeholder="Enter your first name"
						className="w-full p-2 border rounded-md bg-white text-custom-900"
						{...register('firstName', { required: true })}
					/>
					{errors.firstName ? <div className='text-red-500 text-xs h-4'>{errors.firstName.message}</div> : <div className='h-4'></div>}
					<label htmlFor="lastName" className="text-custom-900 text-sm">Last Name:</label>
					<input
						id='lastName'
						type="text"
						placeholder="Enter your last name"
						className="w-full p-2 border rounded-md bg-white text-custom-900"
						{...register('lastName', { required: true })}
					/>
					{errors.lastName ? <div className='text-red-500 text-xs h-4'>{errors.lastName.message}</div> : <div className='h-4'></div>}
					<label htmlFor="projectName" className="text-custom-900 text-sm">Project Name:</label>
					<input
						id='projectName'
						type="text"
						placeholder="Enter your project name"
						className="w-full p-2 border rounded-md bg-white text-custom-900"
						{...register('projectName', { required: true })}
					/>
					{errors.projectName ? <div className='text-red-500 text-xs h-4'>{errors.projectName.message}</div> : <div className='h-4'></div>}
						<button
							disabled={!isValid || !isDirty}
							type="submit"
							className={[isValid && isDirty ? "bg-custom-900 hover:bg-gray-800" : "bg-custom-300", "text-white w-full p-2 rounded-md mt-6"].join(" ")}
						>
							Create an Account
						</button>

				</form>
				<p className="text-sm mt-20 text-center">
					By proceeding with creating an account you agree to the <span className='underline cursor-pointer'>Terms of service</span> and <span className='underline cursor-pointer'>Privacy policy</span>
				</p>
			</div>
		</div>
	);
};

export default CreateAccountPage2;