import React, { useEffect, useState } from 'react';
import TopBar from '../components/TopBar';
import ConnectRepositoryOverlayStep1 from '../components/overlays/ConnectRepositoryOverlayStep1';
import ConnectRepositoryOverlayStep2 from '../components/overlays/ConnectRepositoryOverlayStep2';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAvailableRemotes, getGithubRepo, getLanguages, getProjectsRepo, postGithubCode } from '../api/repositories';
import { ProjectsRepoResponseType, RepoResponseType } from '../types/repositories';
import { AppDispatch } from "../store";
import { useDispatch } from "react-redux";
import { getProject } from '../api/projects';
import RepositoryCard from '../components/RepositoryCard';
import WarningOverlay from '../components/overlays/WarningOverlay';
import SelectLanguageOverlay from '../components/overlays/SelectLanguageOverlay';
import { getMe } from '../api/users';
import LoadingSpinner from '../components/fields/LoadingSpinner';

const CodeAuditPage = () => {
  const [isOpenStep1, setIsOpenStep1] = useState<boolean>(false);
  const [isOpenStep2, setIsOpenStep2] = useState<boolean>(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState<boolean>(false);
	const [repositories, setRepositories] = useState<RepoResponseType[]>([]);
	const [connectedRepositories, setConnectedRepositories] = useState<ProjectsRepoResponseType[]>();
  let location = useLocation();
	const [selected, setSelected] = useState(repositories[0])
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const [projectId, setProjectId] = useState<number>();
	const [languages, setLanguages] = useState<string[]>([]);
	const [selectedRepository, setSelectedRepository] = useState<ProjectsRepoResponseType>();
	const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

	useEffect(() => {
		setIsFetchingData(true);
		dispatch(getProject())
			.then((res) => {
				if (res.payload.length) {
					setProjectId(res.payload[0].id);
				}
			})
			.catch((err) => {
				console.log(err, 'err')
			});
		getLanguages()
			.then((res) => {
				setLanguages(res as string[]);
			})
	}, [dispatch]);

	const handleClick = () => {
		getAvailableRemotes()
			.then((res) => {
				if (res && res.length) {
					getGithubRepo()
						.then((res) => {
							setRepositories(res as RepoResponseType[]);
							setIsOpenStep2(true);
						})
				} else {
					setIsOpenStep1(true);
				}
			});
	}

	useEffect(() => {
		dispatch(getMe())
			.then((res) => {
				if (res.payload.id) {
					localStorage.setItem('userId', res.payload.id.toString()); 
				}
			})
			.catch((err) => {
				console.log(err, 'err')
			});
	}, [dispatch]);
	
	useEffect(() => {
		if (projectId) {
			dispatch(getProjectsRepo({projectId}))
			.then((res) => {
				if (res.payload) {
					setConnectedRepositories(res.payload as ProjectsRepoResponseType[]);}
			})
			.catch((err) => {
				console.log(err, 'err')
			})
			.finally(() => {
				setIsFetchingData(false);
			});
		}
	}, [dispatch, isOpenStep2, projectId, isLanguageModalOpen, isWarningModalOpen, selectedRepository ]);

	useEffect(() => {
		if (location.search.includes('remote=github')) {
			setIsOpenStep2(true)
			const params = new URLSearchParams(window.location.search);
			const code = params.get('code');
				postGithubCode({ code: code as string})
					.then((res) => {
						if (res) {
							getGithubRepo()
								.then((res) => {
									setRepositories(res as RepoResponseType[]);
								})
						}
					}
				);
		}
	}, [location.search]);


	return (
		<div className="bg-white min-h-screen flex items-stretch justify-start font-['Inter'] text-custom-900">
			<div className='mx-10 my-2 h-full w-full flex flex-col justify-start items-center'>
				<TopBar />
				{connectedRepositories && connectedRepositories.length !== 0 && (
					<>
						<div className='w-full flex items-center justify-center md:justify-end '>
							<div 
								className="bg-custom-900 hover:bg-gray-800 text-white w-60 h-10 p-2 rounded-md mb-4 text-center cursor-pointer " 
								onClick={handleClick}
							> 
								Connect Repository
							</div>
						</div>
						<div className='w-full flex flex-wrap items-center justify-center md:justify-start gap-4'>
							{connectedRepositories.map((repository, index) => (
								<RepositoryCard
									key={index}
									repository={repository}
									isConnected={true}
									setIsWarningModalOpen={setIsWarningModalOpen}
									setIsLanguageModalOpen={setIsLanguageModalOpen}
									navigate={navigate}
									setSelectedRepository={setSelectedRepository as React.Dispatch<React.SetStateAction<ProjectsRepoResponseType>>}
								/>
							))}
						</div>
					</>
				)}
				{isFetchingData && <LoadingSpinner />}
				{
					!isFetchingData && !!connectedRepositories && connectedRepositories.length === 0
					&& (
					<div className='flex flex-col items-center justify-center h-full max-w-xl mt-80'>
						<div className='font-semibold text-2xl text-center mb-2'>To get started, connect your first repository</div>
						<div className='font-normal text-md text-center mb-4'>By linking a repository, you enable our tools to dive deep into your code, identifying areas for improvement, potential errors, and security vulnerabilities.</div>
						<div 
						className="bg-custom-900 hover:bg-gray-800 text-white w-60 h-10 p-2 rounded-md mt-6 text-center cursor-pointer"
						onClick={handleClick}
					> 
							Connect Repository
						</div>
					</div>
					)
				}
			</div>
			{isOpenStep1 && (ConnectRepositoryOverlayStep1({ setIsOpenStep1, isOpenStep1 }))}
			{isOpenStep2 && (ConnectRepositoryOverlayStep2({ setIsOpenStep1, setIsOpenStep2, isOpenStep2, repositories, selected, setSelected, projectId: projectId || 0, navigate }))}
			{isWarningModalOpen && <WarningOverlay isWarningModalOpen={isWarningModalOpen} setIsWarningModalOpen={setIsWarningModalOpen} />}
			{isLanguageModalOpen && 
				<SelectLanguageOverlay 
					isLanguageModalOpen={isLanguageModalOpen} 
					setIsWarningModalOpen={setIsWarningModalOpen} 
					setIsLanguageModalOpen={setIsLanguageModalOpen} 
					languages={languages} 
					id={selectedRepository?.id as number}
				/>}
		</div>
	);
};

export default CodeAuditPage;

