import Markdown from 'react-markdown';
import { ErrorObject, ReportType, TechnologiesUsed } from '../../types/general';
import ReportTabDownloadReport from './ReportTabDownloadReport';

interface Props {
  report: ReportType;
  updated: Date;
}

export default function ReportTabTechnologies(props: Props) {
  const { report, updated } = props;

  return (
        <>
          <ReportTabDownloadReport updated={updated} />   
          <table className="min-w-full divide-y divide-custom-50">
            <thead className="">
              <tr>
                <th className="px-6 py-3 text-left text-base leading-7 font-medium text-custom-400 tracking-wider">
                  Technology
                </th>
                <th className="px-6 py-3 text-left text-base leading-7 font-medium text-custom-400 tracking-wider">
                  Description
                </th>
              </tr>
            </thead>   
            <tbody className="bg-white border-custom-50 border divide-y divide-custom-50">
              { !report.technologiesUsed?.hasOwnProperty('error')
                ? (report.technologiesUsed as TechnologiesUsed).technologies.map((technology) => (
                <tr key={technology.technologyName} style={{ borderBottom: '1px solid #E8ECEF' }}>
                  <td className="px-6 py-4 font-medium text-custom-900 text-base leading-7">{technology.technologyName} ({technology.type})</td>
                  <td className="px-6 py-4 font-normal text-custom-900 text-base leading-7"><Markdown>{technology.explanation}</Markdown></td>
                </tr>
              ))
              : <tr>
                  <td colSpan={2} className="px-6 py-4 text-sm font-normal text-custom-900 text-center" style={{ borderBottom: '1px solid #E8ECEF' }}>
                    Error: {(report.technologiesUsed as ErrorObject).error}
                  </td>
                </tr>
            }
            </tbody>
          </table>
          
        </>
           
  )
}
