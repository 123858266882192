import axios, { AxiosError, AxiosResponse } from 'axios';
import { instance } from './index';
import { TPostCodeSuccess } from '../types/auth';
import { CodeType } from '../types/users';
import { PostRepoDataType, PostRepoResponseType, RepoIdType, RepoResponseType } from '../types/repositories';
import { ProjectIdType } from '../types/projects';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const postGithubCode = (
  data: CodeType,
): Promise<TPostCodeSuccess | void> => {
  
  return instance
    .post('/remote-repos/github/auth', data)
    .then(function (response: AxiosResponse<TPostCodeSuccess>) {
      return response.data;
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, 'error');
        const message = error?.response?.data.error;
        throw new Error(message);
      }
    });
};

export const getGithubRepo = (): Promise<RepoResponseType[] | void> => {
  return instance
    .get('/remote-repos/github/repositories')
    .then(function (response: AxiosResponse<RepoResponseType[]>) {
      return response.data;
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, 'error');
        const message = error?.response?.data.error;
        throw new Error(message);
      }
    });
};

export const getAvailableRemotes = (): Promise<string[] | void> => {
  return instance
    .get('/remote-repos/available-remotes')
    .then(function (response: AxiosResponse<string[]>) {
      return response.data as string[];
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, 'error');
        const message = error?.response?.data.error;
        throw new Error(message);
      }
    });
};

export const postRepositories = (data: PostRepoDataType): Promise<PostRepoResponseType | void> => {
  return instance
    .post('/repositories', data)
    .then(function (response: AxiosResponse<PostRepoResponseType>) {
      return response.data;
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, 'error');
        const message = error?.response?.data.error;
        throw new Error(message);
      }
    });
};

export const getLanguages = (): Promise<string[] | void> => {
  return instance
    .get('/available-languages')
    .then(function (response: AxiosResponse<string[]>) {
      return response.data;
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, 'error');
        const message = error?.response?.data.error;
        throw new Error(message);
      }
    });
};

export const deleteRepo = (data: RepoIdType): Promise<string[] | void> => {
  const id = data.id;
  return instance
    .delete(`/repositories/${id}`)
    .then(function (response: AxiosResponse<string[]>) {
      return response.data;
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, 'error');
        const message = error?.response?.data.error;
        throw new Error(message);
      }
    });
};

const controller = new AbortController();

export const getProjectsRepo = createAsyncThunk(
  'repositories/getProjectsRepo',
  async (data: ProjectIdType, { signal }) => {
    const projectId = data.projectId;
    signal.addEventListener('abort', () => {
      controller.abort();
    });
    const response: AxiosResponse = await instance.get('/repositories', { data: { projectId } });
    return response.data;
  }
);