import React, { FunctionComponent, ReactNode } from 'react'
import { useTextSelection } from 'use-text-selection'
import { createPortal } from 'react-dom'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import { emails } from '../reportTabs/ReportTabQuality/emails'
const Portal: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  return createPortal(children, document.body)
}

interface PopoverProps {
  setShowChat: (value: boolean) => void
  showChat: boolean;
}

export const Popover: React.FC<PopoverProps> = ({ setShowChat, showChat }) => {
  const { isCollapsed, clientRect } = useTextSelection()
  const userEmail = useSelector((state: RootState) => state.user.email);

  const isWhitelisted = emails.some(email => email === userEmail)
  if (clientRect == null || isCollapsed) return null

  const style: React.CSSProperties  = {
    position: 'absolute',
    left: `${clientRect.left + clientRect.width / 2}px`,
    top: `${window.scrollY + clientRect.top - 50}px`,
    marginLeft: '-50px',
    maxWidth: '500px',
    padding: '10px',
    background: '#e0e2e4',
    border: 'none',
    textAlign: 'center',
    color: '#2F3F4C',
    borderRadius: '8px',
  };
  if (!showChat && isWhitelisted) {
    return <Portal>
        <button style={style} id='portal'
          onClick={(e)=>{
            e.stopPropagation()
            setShowChat(true)
          }}
          >
          Ask AI
        </button>
    </Portal>
  } else {
    return null
  }
}
