import React from 'react';
import TopBar from '../components/TopBar';
import ReportTabs from '../components/reportTabs/ReportTabs';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

const ReportPage = () => {
	const navigate = useNavigate();


	return (
		<div className="bg-white min-h-screen flex items-stretch justify-start font-['Inter'] text-custom-900">
			<div className='mx-10 my-2 h-full w-full flex flex-col justify-start items-center'>
				<TopBar breadcrumb='Code Audit / Best-repository... History / Code Audit Report'/>
				<div className='flex flex-col items-start justify-center h-full w-full '>
						<div className="text-custom-900 hover:font-bold mb-4 flex w-full items-center gap-2 justify-start cursor-pointer" onClick={() => navigate(-1)}>
							<IconArrowLeft size={20} />
							Back
						</div>
            <div className='font-semibold text-2xl text-center mb-2'>
              Code Audit Report
            </div>
            <ReportTabs />
        </div>
			</div>
		</div>
	);
};

export default ReportPage;

