import Score0 from '../assets/Score0.svg';
import Score1 from '../assets/Score1.svg';
import Score2 from '../assets/Score2.svg';
import Score3 from '../assets/Score3.svg';
import Score4 from '../assets/Score4.svg';
import Score5 from '../assets/Score5.svg';
import Score6 from '../assets/Score6.svg';
import Score7 from '../assets/Score7.svg';
import Score8 from '../assets/Score8.svg';
import Score9 from '../assets/Score9.svg';
import Score10 from '../assets/Score10.svg';


export default function getScoreIcon(score: number) {
  const roundedScore = Math.round(score);
  switch (roundedScore) {
    case 0:
      return Score0;
    case 1:
      return Score1;
    case 2:
      return Score2;
    case 3:
      return Score3;
    case 4:
      return Score4;
    case 5:
      return Score5;
    case 6:
      return Score6;
    case 7:
      return Score7;
    case 8:
      return Score8;
    case 9:
      return Score9;
    case 10:
      return Score10;
    default:
      return Score0;
  }
}
