type IsaveUserTokens = {
  token: string;
  refreshToken: string;
  email: string;
}

export default function saveUserTokens(data: IsaveUserTokens): void {
  const {token, refreshToken, email } = data;
  localStorage.setItem('token', token)
  localStorage.setItem('refreshToken', refreshToken)
  localStorage.setItem('email', email)
}