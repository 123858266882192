import { Tab } from '@headlessui/react'
import { ReportType } from '../../types/general';
import ReportTabGeneral from './ReportTabGeneral';
import ReportTabTechnologies from './ReportTabTechnologies';
import ReportTabLicenses from './ReportTabLicenses';
import ReportTabQuality from './ReportTabQuality';
import getTabName from '../../utils/getTabNames';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { getAuditByAuditId } from '../../api/audits';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../fields/LoadingSpinner';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function ReportTabs() {
  const [reportData, setReportData] = useState<ReportType>();
  const [updated, setUpdated] = useState<Date>();
	const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  
  const { auditId } = useParams<{ auditId: string }>();

  useEffect(() => {
    if (auditId) {
      setIsFetchingData(true);
      dispatch(getAuditByAuditId(auditId as string))
        .then((res) => {
          if (res.payload) {
            const reportDataObject = JSON.parse(res.payload?.body);
            const updatedDate = new Date(res.payload?.updatedAt);
            setUpdated(updatedDate);
            setReportData(reportDataObject);
          }
        })
        .catch((err) => {
          console.log(err, 'err')
        })
        .finally(() => {
          setIsFetchingData(false);
        });
    }
  }, [auditId, dispatch]);

  const hasError = (reportData && reportData?.error) || !reportData;


  return (
    <div className="w-full px-2 py-4 sm:px-0">
      {isFetchingData && <LoadingSpinner />}
      {hasError && !isFetchingData 
        && 
        <div className="w-full h-full flex items-center justify-center">
          <div className="text-custom-900 text-2xl font-semibold">Error: {reportData?.error || "audit report not found"}</div>
        </div>
      }
      {!hasError && !isFetchingData && !!reportData && (
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl border border-custom-50 p-1 w-1/2 ">
          {Object.keys(reportData).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                selected
                  ? 'bg-custom-50 text-custom-900 shadow font-bold'
                  : 'text-custom-900 hover:bg-custom-100 font-medium hover:font-semibold',
                  'w-full rounded-lg py-2.5 text-base leading-7',
                  'ring-white/60 ring-offset-2 ring-offset-custom-300 focus:outline-none',
                )
              }
            >
              {getTabName(category)}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2 relative">
            <Tab.Panel
              key={1}
              className={classNames(
                'rounded-xl bg-white p-3',
                'ring-white/60 ring-offset-2'
              )}
            >
              <ReportTabQuality report={reportData} updated={updated as Date} />
            </Tab.Panel>
            <Tab.Panel
              key={2}
              className={classNames(
                'rounded-xl bg-white border-custom-50 border p-3',
                'ring-white/60 ring-offset-2'
              )}
            >
              <ReportTabLicenses report={reportData} updated={updated as Date} />
            </Tab.Panel>
            <Tab.Panel
              key={3}
              className={classNames(
                'rounded-xl bg-white border-custom-50 border p-3',
                'ring-white/60 ring-offset-2'
              )}
            >
              <ul>
                <ReportTabGeneral report={reportData} updated={updated as Date} />
              </ul>
            </Tab.Panel>
            <Tab.Panel
              key={4}
              className={classNames(
                'rounded-xl bg-white border-custom-50 border p-3',
                'ring-white/60 ring-offset-2'
              )}
            >
              <ReportTabTechnologies report={reportData} updated={updated as Date} />
            </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      )
    }
    </div>
  )
}
