import React, { useState } from 'react';
import LogoutOverlay from './overlays/LogoutOverlay';
import UserAvatar from './UserAvatar';
import { Link } from 'react-router-dom';
import Logo from './fields/Logo';

interface Props {
	breadcrumb?: string
}

const TopBar = (props: Props) => {
	const { breadcrumb } = props;
	const email = localStorage.getItem('email');
	const [isLogoutOverlayOpen, setIsLogoutOverlayOpen] = useState<boolean>(false);

	return (
    <div className='flex justify-between items-center w-full mb-10'>
			<div className='flex items-center justify-start gap-8 h-16 w-full'>
				<Link to="/dashboard">
					<Logo />
				</Link>
				<div className='text-xs'>{breadcrumb || 'Code Audit / code_audit'}</div>
      </div>
			<div 
				className='flex items-center justify-center gap-3 h-16 cursor-pointer'
				onClick={() => setIsLogoutOverlayOpen(true)}
			>
				<UserAvatar userName={email as string} />
			</div>
			{isLogoutOverlayOpen 
				&& 
			<LogoutOverlay setIsLogoutOverlayOpen={setIsLogoutOverlayOpen} isLogoutOverlayOpen={isLogoutOverlayOpen} userName={email as string} />
			}
    </div>
	);
};

export default TopBar;