export default function getReportTypeNames (reportTypeName: string) {
  switch (reportTypeName) {
    case 'errorHandlingReport':
      return 'Error Handling';
    case 'codeQualityReport':
      return 'Code Quality';
    case 'performanceReport':
      return 'Performance';
    case 'scalabilityReport':
      return 'Scalability';
    case 'documentationReport':
      return 'Documentation';
    default:
      return '';
  }
}