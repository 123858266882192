import { Transition, Dialog } from "@headlessui/react";
import { IconX } from "@tabler/icons-react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  isWarningModalOpen: boolean;
  setIsWarningModalOpen: (isOpenStep1: boolean) => void;
}

const WarningOverlay = (props: Props) => {
    const { isWarningModalOpen, setIsWarningModalOpen } = props;
    const userId = localStorage.getItem("userId")
    const localStorageData = localStorage.getItem(userId as string);
    const [skipMessage, setSkipMessage] = useState<boolean>(localStorageData ? JSON.parse(localStorageData).doNotShowWarningModal : false);
    const navigate = useNavigate();

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSkipMessage(event.target.checked);
      let data = localStorageData ? JSON.parse(localStorageData) : {};
      data = { doNotShowWarningModal: event.target.checked };
      localStorage.setItem(userId as string, JSON.stringify(data));
    };
  
    return (
      <Transition.Root show={isWarningModalOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={() => setIsWarningModalOpen(false)}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-black/40" />

          <div className="fixed w-5/6 sm:w-[500px] h-60 left-0 right-0 mx-auto my-auto top-0 bottom-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <div className="w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-2 right-2 flex">
                    <button
                      type="button"
                      className="text-custom-400"
                      onClick={() => setIsWarningModalOpen(false)}
                    >
                      <IconX className="h-6 w-6" />
                    </button>
                  </div>
                </Transition.Child>
                <div id="styled-scroll" className="grow flex flex-col h-full bg-white shadow-xl overflow-y-auto rounded-2xl">
                  <div className='grow flex gap-4 flex-col justify-start items-center h-full rounded bg-white px-4' onClick={e => e.stopPropagation()}>
                    <div className="font-semibold text-2xl pt-10">Warning</div>
                    <div className="text-sm font-normal">Please be aware that the code audit can take a while to complete.</div>
                  </div>
                  <div className="border-t border-custom-50 flex justify-between items-center">
                    <div className="flex justify-start gap-4 items-center">
                      <input
                        type="checkbox"
                        className={`ml-6 h-5 w-5 ${skipMessage ? 'accent-custom-900' : ''}`}
                        checked={skipMessage}
                        onChange={handleCheckboxChange}
                      />
                      <p className="font-medium text-base">Skip message in the future</p>
                    </div>
                  <div 
                    className="bg-custom-900 hover:bg-gray-800 text-white w-40 h-10 p-2 rounded-md m-6 text-center cursor-pointer"
                    onClick={() => {
                      setIsWarningModalOpen(false)
                      navigate('/dashboard')
                    }}
                  > 
                    Continue
                  </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    );
  };
  
  export default WarningOverlay;