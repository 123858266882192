import { IconBrandJavascript, IconBrandPython, IconBrandTypescript, IconBrandCSharp, IconBrandPhp, IconCodeDots } from '@tabler/icons-react';

export function getIcon(language: string) {
  switch (language) {
    case 'JavaScript':
      return <IconBrandJavascript className='w-14 h-14 text-custom-900' />;
    case 'Python':
      return <IconBrandPython className='w-14 h-14 text-custom-900' />;
    case 'TypeScript':
      return <IconBrandTypescript className='w-14 h-14 text-custom-900' />;
    case 'Csharp':
      return <IconBrandCSharp className='w-14 h-14 text-custom-900' />;
    case 'Php':
      return <IconBrandPhp className='w-14 h-14 text-custom-900' />;
    default:
      return <IconCodeDots className='w-14 h-14 text-custom-900' />;
  }
}