import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import CreateAccountPage from '../pages/CreateAccountPage';
import LoginPage from '../pages/LoginPage';
import CreateAccountPage2 from '../pages/CreateAccountPage2';
import CodeAuditPage from '../pages/CodeAuditPage';
import ReportPage from '../pages/ReportPage';
import useAuth from '../hooks/UseAuth';
import HistoryPage from '../pages/HistoryPage';

export default function Router() {
  const isLogged = useAuth();

  return (
    <Routes>

      <Route
        element={
          <ProtectedRoute isAllowed={isLogged} redirectPath="/signin" />
        }
      >
        <Route path="*" Component={CodeAuditPage} />
      </Route>

      <Route
        element={
          <ProtectedRoute isAllowed={isLogged} redirectPath="/signin" />
        }
      >
        <Route path="/code-audits-history/:id" Component={HistoryPage} />
      </Route>

      <Route
        element={
          <ProtectedRoute isAllowed={!isLogged} redirectPath="/dashboard" />
        }
      >
        <Route path="/signin" Component={LoginPage} />
      </Route>

      <Route
        element={
          <ProtectedRoute isAllowed={!isLogged} redirectPath="/signup2" />
        }
      >
        <Route path="/signup" Component={CreateAccountPage} />
      </Route>
      
      <Route
        element={
          <ProtectedRoute isAllowed={isLogged} redirectPath="/dashboard" />
        }
      >
        <Route path="/signup2" Component={CreateAccountPage2} />
      </Route>


      <Route
        element={
          <ProtectedRoute isAllowed={isLogged} redirectPath="/signin" />
        }
      >
        <Route path="/dashboard" Component={CodeAuditPage} />
      </Route>
      
      <Route
        element={
          <ProtectedRoute isAllowed={isLogged} redirectPath="/signin" />
        }
      >
        <Route path="/report/:auditId" Component={ReportPage} />
      </Route>
    </Routes>
  );
}
