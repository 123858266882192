import { Transition, Dialog } from "@headlessui/react";
import { IconX } from "@tabler/icons-react";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../store";
import { postAudit } from "../../api/audits";

interface Props {
  isLanguageModalOpen: boolean;
  setIsLanguageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsWarningModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  languages: string[];
  id: number;
}

const SelectLanguageOverlay = (props: Props) => {
    const { isLanguageModalOpen, setIsWarningModalOpen, setIsLanguageModalOpen, languages, id } = props;
    const userId = localStorage.getItem("userId")
    const localStorageData = localStorage.getItem(userId as string);
    const [skipMessage, setSkipMessage] = useState<boolean>(localStorageData ? JSON.parse(localStorageData).doNotShowWarningModal : false);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [selectedLanguage, setSelectedLanguage] = useState<string>(languages[0]);
  
    return (
      <Transition.Root show={isLanguageModalOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={() => setIsLanguageModalOpen(false)}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-black/40" />

          <div className="fixed w-5/6 sm:w-[500px] h-80 left-0 right-0 mx-auto my-auto top-0 bottom-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <div className="w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-2 right-2 flex">
                    <button
                      type="button"
                      className="text-custom-400"
                      onClick={() => setIsLanguageModalOpen(false)}
                    >
                      <IconX className="h-6 w-6" />
                    </button>
                  </div>
                </Transition.Child>
                <div id="styled-scroll" className="grow flex flex-col h-full bg-white shadow-xl overflow-y-auto rounded-2xl">
                  <div className='grow flex gap-3 flex-col justify-start items-center h-full rounded bg-white px-4' onClick={e => e.stopPropagation()}>
                    <div className="font-semibold text-2xl pt-4">Request Code Audit</div>
                    <div className="text-base font-normal self-start">Supported language:</div>
                    <select 
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-custom-400"
                      onChange={(e) => setSelectedLanguage(e.target.value)} 
                    >
                      {languages.map((language, index) => (
                        <option key={index} value={language}>{language}</option>
                      ))}
                    </select>
                    <div className="text-base">Please note that this process may take some time. You will be notified once it's complete. </div>
                  </div>

                  <div className="border-t border-custom-50 flex justify-between items-center">
                    <div 
                      className="bg-custom-50 hover:bg-custom-200 text-custom-900 w-40 h-10 p-2 border border-custom-500 rounded-md m-6 text-center cursor-pointer"
                      onClick={() => setIsLanguageModalOpen(false)}
                    > 
                      Cancel
                    </div>
                    <div 
                      className="bg-custom-900 hover:bg-gray-800 text-white w-40 h-10 p-2 rounded-md m-6 text-center cursor-pointer"
                      onClick={() => {
                        dispatch(postAudit({ repositoryId: id, "language": selectedLanguage as string }));
                        if (skipMessage) {
                          setIsLanguageModalOpen(false)
                          navigate('/dashboard')
                        } else {
                          setIsLanguageModalOpen(false)
                          setIsWarningModalOpen(true)
                        }
                      }}
                    > 
                      Continue
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    );
  };
  
  export default SelectLanguageOverlay;