import axios from 'axios';
import { refresh } from './auth';

const JWT = localStorage.getItem('token')


export const instance = axios.create({
  baseURL: 'https://code-audit-web-backend-940d30d62852.herokuapp.com/',
  headers: { 'authorization': JWT },
});


// Response interceptor for API calls
instance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry) {
    originalRequest._retry = true;
    await refresh();
    return instance(originalRequest);
  }
  return Promise.reject(error);
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token;
   
  return config;
});