import { AxiosResponse } from 'axios';
import { instance } from './index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuditPostType } from '../types/audits';

const controller = new AbortController();

export const getAuditsByRepoId = createAsyncThunk(
  'audits/getAudits',
  async (id: string, { signal }) => {;
    signal.addEventListener('abort', () => {
      controller.abort();
    });
    const response: AxiosResponse = await instance.get('/audits', { params: { repositoryId: id } });
    return response.data;
  }
);

export const getAuditByAuditId = createAsyncThunk(
  'audits/getAudit',
  async (id: string, { signal }) => {;
    signal.addEventListener('abort', () => {
      controller.abort();
    });
    const response: AxiosResponse = await instance.get(`/audits/${id}`);
    return response.data;
  }
);

export const postAudit = createAsyncThunk(
  'audits/postAudits',
  async (data: AuditPostType, { signal }) => {;
    signal.addEventListener('abort', () => {
      controller.abort();
    });
    const response: AxiosResponse = await instance.post('/audits', data );
    return response.data;
  }
);