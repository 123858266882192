import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setEmail, setLogged, setLogout } from '../store/userSlice';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { refresh } from '../api/auth';
import { debounce } from 'lodash';

export default function useAuth() {
  const dispatch = useDispatch<AppDispatch>();
  const isToken = Boolean(
    localStorage.getItem('token') || localStorage.getItem('token')
  );
  const {isLogged, email} = useSelector((store: RootState) => store.user);

  useEffect(() => {
    if (isToken) {
      dispatch(setLogged());
    } else {
      dispatch(setLogout());
    }
  }, [dispatch, isToken]);

  const refreshDebounce = debounce(() => {
    refresh().then((res) => dispatch(setEmail(res || "")))
  }, 300)

  useEffect(() => {
    if (!email) {
      refreshDebounce()
    }
  }, []);

  return isLogged || isToken;
}