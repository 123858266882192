import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getInitials } from "../../UserAvatar";
import { IconRobot, IconSend, IconX } from "@tabler/icons-react";
import { Explanation } from ".";
import ReactMarkdown from 'react-markdown';

export function ChatModal(props: {
  textAreaValue: string;
  setTextAreaValue: (value: string) => void,
  explanation?: Explanation
  onClose: () => void
  onSend: () => void
  answerLoading: boolean
}){
  const { textAreaValue, setTextAreaValue, explanation, onClose, onSend, answerLoading } = props 

  const inputRef = useRef<HTMLInputElement>(null)
  const questionsList = useRef<HTMLUListElement>(null)

  const userEmail = useSelector((state: RootState) => state.user.email);

  // It will close on press ESC
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => { if (event.key === 'Escape') onClose() }

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, []);

  useEffect(() => {
    questionsList.current?.scrollTo(0, questionsList.current.scrollHeight)
  }, [])

  return (
    <div 
      className="z-50 text-[#2F3F4C] h-full rounded-lg border text-center shadow-md shadow-zinc-500 
        flex flex-col justify-between items-center relative w-full bg-white" 
      >
      <header className='w-full px-4 rounded-t-lg shadow-md shadow-custom-300 border-b-2 bg-white py-6 flex items-center justify-between'>
        <h2 className='font-bold'>AI Assistant:</h2>

        <IconX onClick={onClose} className='cursor-pointer hover:opacity-50 transition-opacity' size={18} />
      </header>

      <ul id="chat-messages" ref={questionsList} className='mt-4 px-2 flex-1 w-full flex flex-col gap-2 mb-4 overflow-y-auto'>
        {explanation?.messages?.map((message, idx) => {
          if (message.from === `AI`) {
            return (
              <li className='flex flex-row items-start'>
                <div className='mt-1 mx-2 text-sm flex items-center justify-center rounded-full w-8 h-8 bg-custom-50'>
                  <IconRobot />
                </div>
                <div className='bg-custom-50 rounded-b-xl rounded-tr-xl basis-2/3 border text-black w-full text-left px-4 py-2 ml-2'>
                  <ReactMarkdown>{message.message}</ReactMarkdown>
                </div>
              </li>
            )
          }

          return (
            <li className='flex flex-row-reverse items-start'>
              <div className='mt-1 mx-2 text-sm flex items-center justify-center rounded-full w-8 h-8 bg-indigo-600 text-zinc-50'>
                {userEmail && getInitials(userEmail)}
              </div>
              <div className='bg-indigo-900 rounded-b-xl rounded-tl-xl basis-2/3 border text-white w-full text-right px-4 py-2 ml-2'>
                <h2 className=''>{message.message}</h2>
              </div>
            </li>
          )
        })}

        {answerLoading && (
          <li className='flex flex-row items-start'>
            <div className='mt-1 mx-2 text-sm flex items-center justify-center rounded-full w-8 h-8 bg-custom-50'>
              <IconRobot />
            </div>
            <div className='bg-custom-50 rounded-b-xl rounded-tr-xl basis-2/3 border text-black w-full text-left px-4 py-2 ml-2'>
              ...
            </div>
          </li>
        )}
      </ul>

      <div 
        onClick={(e) => {
          e.stopPropagation()
          inputRef?.current?.focus()
        }} 
        className='w-full px-4 pb-4'
      >
        <form onSubmit={e => {
          e.preventDefault()
          onSend()
        }} className='w-full flex items-center justify-center relative'>
          <input
            placeholder='Ask something to AI'
            ref={inputRef}
            className="
              rounded-2xl text-custom-900 pr-12 pl-4 min-h-14 w-full resize-none 
              active:outline-none focus:outline-none shadow-sm
              border-2
            "
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
          ></input>
          
          <button
            className="absolute top-[45%] -translate-y-1/2 right-0 h-6 w-12 cursor-pointer"
            type="submit"
          >
            <IconSend className='text-zinc-950 w-7 h-7 rounded-md p-1 hover:bg-zinc-200 transition-all' />
          </button>
        </form>
      </div>
    </div>
  )
}