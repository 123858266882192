export default function getTabName (tabName: string) {
  switch (tabName) {
    case 'codeQuality':
      return 'Code Audit';
    case 'technologiesUsed':
      return 'Technologies Used';
    case 'generalStatistic':
      return 'General';
    case 'licenseCheck':
      return 'List of Licenses';
    default:
      return '';
  }
}