import { IconInnerShadowTop } from '@tabler/icons-react';
import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className='w-full min-h-80 flex items-center justify-center'>
      <IconInnerShadowTop className='w-20 h-20 animate-spin text-custom-900' />
    </div>
  );
};

export default LoadingSpinner;