import React from 'react';
import { ReportType } from '../../types/general';
import ReportTabDownloadReport from './ReportTabDownloadReport';
import { useState } from 'react';
import { IconChevronDown } from '@tabler/icons-react';

interface Props {
  report: ReportType;
  updated: Date;
}

export default function ReportTabLicenses(props: Props) {
  const { report, updated } = props;
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const toggleRow = (license: string) => {
    if (expandedRows.includes(license)) {
      setExpandedRows(expandedRows.filter(row => row !== license));
    } else {
      setExpandedRows([...expandedRows, license]);
    }
  };

  return (
        <>
          <ReportTabDownloadReport updated={updated} />   
          <table className="min-w-full divide-y divide-custom-50 text-base leading-7">
            <thead className="">
              <tr>
                <th className="px-6 py-3 text-left font-medium text-custom-400 tracking-wider">
                  License
                </th>
                <th className="px-6 py-3 text-left font-medium text-custom-400 tracking-wider">
                  Number of Packages
                </th>
              </tr>
            </thead>   
            <tbody className="bg-white border-custom-50 border divide-y divide-custom-50">
              {Object.entries(report.licenseCheck).map(([license, data]) => (
                <React.Fragment key={license}>
                  <tr 
                    onClick={() => toggleRow(license)}
                    style={{ borderBottom: '1px solid #E8ECEF' }}
                  >
                    <td 
                      className="px-6 py-3 text-left font-medium text-custom-900 cursor-pointer "
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleRow(license);
                      }}
                    >
                      <span>{license}</span>
                      <IconChevronDown
                        className={`${
                          expandedRows.includes(license) ? 'transform rotate-180' : ''
                        } inline-block ml-2 transition-transform duration-200 h-5 w-5`}
                      />
                      {expandedRows.includes(license) && (
                          <div className=''>
                            <ul>
                              {data.packages.map((item) => (
                                <li key={item} className='px-4 pt-2 text-sm'>{item}</li>
                              ))}
                            </ul>
                          </div>
                      )}
                    </td>
                    <td className="px-6 py-3 text-left text-custom-400">{data.totalCount}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
          
        </>
           
  )
}
