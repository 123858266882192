import { RadioGroup } from '@headlessui/react'
import { IconBook2, IconCircle, IconCircleCheck } from '@tabler/icons-react'
import { RepoResponseType } from '../types/repositories';

interface Props {
  repositories: RepoResponseType[];
  selected: RepoResponseType;
  setSelected: React.Dispatch<React.SetStateAction<RepoResponseType>>;
}

export default function ConnectRepositoryRadioStep2(Props: Props) {
  const { repositories, setSelected } = Props;

  return (
    <div className="w-full px-4">
      <div className="mx-auto w-full max-w-md">
        <RadioGroup>
          <RadioGroup.Label className="sr-only">Name of repository</RadioGroup.Label>
          <div className="space-y-2">
            
            {repositories
              .filter((repository) => !repository.connected)
              .map((repository) => (
              <RadioGroup.Option
                key={repository.name}
                value={repository.name}
                className={({ active, checked }) =>
                  `${
                    active
                      ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-custom-50'
                      : ''
                  }
                  ${checked ? 'bg-custom-900/75 text-white' : 'bg-white'}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }
                onClick={() => setSelected(repository)}
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-start gap-3">
                      {checked ? (
                        <div className="shrink-0 text-white">
                          <IconCircleCheck className="h-6 w-6" />
                        </div>
                        ) 
                        : 
                        <div className="shrink-0 text-custom-900">
                          <IconCircle className="h-6 w-6" />
                        </div>
                      }
                      <IconBook2 className="h-6 w-6 shrink-0" />
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? 'text-white' : 'text-custom-900'
                            }`}
                          >
                            {repository.name}
                          </RadioGroup.Label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  )
}
