import { ReportType } from '../../../types/general';
import ReportTabDownloadReport from '../ReportTabDownloadReport';
import getScoreIcon from '../../../utils/getScoreIcon';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import getReportTypeNames from '../../../utils/getReportTypeNames';
import ReactMarkdown, { ExtraProps } from 'react-markdown';
import { Popover } from '../../fields/Popover';
import { useTextSelection } from 'use-text-selection'
import { FilesChecked } from './FilesChecked';
import { IconLoader, IconMessage } from '@tabler/icons-react'
import { createPortal } from 'react-dom'
import './scrollbar.css'
import { ChatModal } from './Chat';
import useWebSocket, { ReadyState } from "react-use-websocket"
import { useParams } from 'react-router-dom';

// -start- websockets
interface WSResponse {
  message?: string,
  error?: string,
  connectionId?: string,
  response?: string,
  explanationId?: string,
}

export const ws = {
  URL: "wss://code-audit-web-backend-940d30d62852.herokuapp.com/audits/bot",

  connect: (auditId: number, authToken: string | null) => ({
    type: `CONNECT`,
    data: { auditId, authToken }
  }),

  reconnect: (data: { connectionId: string, authToken: string }) => ({ type: `RECONNECT`, data }),

  disconnect: () => ({ type: "DISCONNECT" }),

  explanationSession: {
    create: () => ({ type: "NEW_EXPLANATION_SESSION" }),
    delete: () => ({ type: "DELETE_EXPLANATION_SESSION" }),
  },

  explanation: {
    create: (data: { question: string, report: string, source: string, text: string }) => ({ type: "NEW_EXPLANATION", data }),
    delete: (explanationId: string) => ({ type: "DELETE_EXPLANATION", data: { explanationId} })
  },

  question: {
    create: (data: { question: string, explanationId: string }) => ({ type: "QUESTION", data })
  }
}
// -end- websockets

export interface Explanation {
  id?: string,
  report: string,
  source: string,
  text: string,
  top: number
  messages: Array<{ message: string, from: 'AI' | 'USER'}>
}

const Portal = ({ children }: PropsWithChildren) => createPortal(children, document.body)

export default function ReportTabQuality({ report, updated }: { report: ReportType, updated: Date }) {
  const { auditId } = useParams<{ auditId: string }>();
  const { textContent, clientRect } = useTextSelection();

  const { sendJsonMessage: _sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    ws.URL,
    {
      share: false,
      shouldReconnect: () => true,
    },
  )

  const sendJsonMessage = useCallback((data: any) => {
    console.log('SEND:', data)
    _sendJsonMessage(data)
  }, [_sendJsonMessage])

  const [connected, setConnected] = useState(false)
  const [uncollapsedFiles, setUncollapsedFiles] = useState<string[]>([]);
  const [explanationId, setExplanationId] = useState<string>()
  const [explanations, setExplanations] = useState<Array<Explanation>>([])
  const [explanationSessionIsOpen, setExplanationSessionIsOpen] = useState(false)

  const [showChat, setShowChat] = useState(false)

  // new question or explanation fields
  const [top, setTop] = useState<number>()
  const [inputValue, setInputValue] = useState<string>()
  const [source, setSource] = useState<string>()
  const [text, setText] = useState<string>()
  const [questionReportType, setQuestionReportType] = useState<string>()
  const [answerLoading, setAnswerLoading] = useState(false)

  const clearNewQuestionFields = () => {
    setInputValue(undefined)
    setSource(undefined)
    setText(undefined)
    setTop(undefined)
    setQuestionReportType(undefined)
  }

  const handleCreateExplanation = (data: { question: string; top: number, report: string; source: string; text: string; }) => {
    sendJsonMessage(ws.explanation.create({
      source: data.source,
      report: data.report,
      text: data.text,
      question: data.question
    }))

    setAnswerLoading(true)

    setExplanations(prev => [
      ...prev, {
        source: data.source,
        report: data.report,
        text: data.text,
        top: data.top,
        messages: [
          {
            from: 'USER',
            message: data.question,
          }
        ]
      }
    ])
  }

  const handleCreateQuestion = () => {
    const question = {
      question: inputValue as string,
      explanationId: explanationId as string
    }
    setExplanations(prev => prev.map(explanation => {
      if (explanation.id !== explanationId) {
        return explanation;
      }
      return {
        ...explanation,
        messages: [
          ...explanation.messages,
          {
            from: 'USER',
            message: question.question
          }
        ]
      }
    }))
    sendJsonMessage(ws.question.create(question))
    setAnswerLoading(true)
  }

  const handleSendMessage = () => {
    if (explanations.some(explanation => explanation.id === explanationId)) {
      handleCreateQuestion()
      clearNewQuestionFields()
      return;
    }
    
    const newExplanation = {
      question: inputValue as string,
      source: source as string,
      report: questionReportType as string,
      top: top as number,
      text: text as string
    }

    handleCreateExplanation(newExplanation)
    clearNewQuestionFields()
    setAnswerLoading(true)
  }

  const handleCloseChat = () => {
    setExplanationId(undefined)
    setInputValue(undefined)
    setShowChat(false)
  }

  const handleOpenChat = () => {
    if (explanationSessionIsOpen) {
      setExplanationId(undefined)
      setShowChat(true)
      return;
    }
    sendJsonMessage(ws.explanationSession.create())
  }

  const handleDisconnect = useCallback(() => {
    sendJsonMessage(ws.disconnect())
    setConnected(false)
  }, [sendJsonMessage])


  useEffect(() => {
    if (!connected && readyState === ReadyState.OPEN) {
      // if (localStorage.getItem(`connectionId`) !== null) {
      //   //reconnect
      //   sendJsonMessage(ws.reconnect({
      //     authToken: localStorage.getItem(`token`) as string,
      //     connectionId: localStorage.getItem(`connectionId`) as string,
      //   }))
      //   setConnected(true)
      //   return;
      // }

      sendJsonMessage(ws.connect(Number(auditId), localStorage.getItem(`token`)))
      setConnected(true)
    }
  }, [readyState, connected, sendJsonMessage, auditId])

  // ws responses
  useEffect(() => {
    const response = lastJsonMessage as WSResponse

    console.log("RECEIVED: ", response)

    if (response?.error) {
      setAnswerLoading(false)
    }

    // connect response
    if (response?.message === `Connections established` &&  response?.connectionId) {
      setConnected(true)
      localStorage.setItem(`connectionId`, response.connectionId)
      return;
    }

    // reconnect response
    if (response?.message === `Connection restored`) {
      setConnected(true)
      return;
    }

    // connect error
    if (response?.error === "Connection not found") {
      setConnected(false)
      localStorage.removeItem(`connectionId`)
      return;
    }

    // new explanation session response
    if (response?.message === "New Explanation Session Started") {
      setExplanationSessionIsOpen(true)
      setShowChat(true)
      return;
    }

    // new explanation response
    if (response?.response && response?.explanationId) {
      setExplanationId(response?.explanationId)
      setExplanations(prev => prev.map(explanation => {
        if (explanation.id) {
          return explanation
        }

        return {
          ...explanation,
          id: response?.explanationId,
          messages: [
            ...explanation.messages,
            {
              from: 'AI',
              message: response.response as string
            }
          ]
        }
      }))
      setAnswerLoading(false)
      return;
    }

    // explanation delete response
    if (response?.message === "Explanation chat deleted") {
      sendJsonMessage(ws.explanationSession.delete())
      setExplanationId(undefined)
      return;
    }

    // explanation session delete response
    if (response?.message === "Explanation session deleted"){
      setExplanationSessionIsOpen(false)
      handleDisconnect()
      return;
    }

  }, [lastJsonMessage, sendJsonMessage, handleDisconnect])

  // just response for send question
  useEffect(() => {
    const response = lastJsonMessage as WSResponse

    if (response?.response && !response?.explanationId) {
      setExplanations(prev => prev.map(explanation => {
        if (explanation.id !== explanationId) {
          return explanation
        }

        return {
          ...explanation,
          messages: [
            ...explanation.messages,
            {
              from: 'AI',
              message: response.response as string
            }
          ]
        }
      }))
      setAnswerLoading(false)
      return;
    }
  }, [lastJsonMessage, explanationId])

  // Update rect and textContent on any of them change
  useEffect(() => {
    if (!showChat) {
      setTop(window.scrollY + (clientRect?.top || 0))
      setText(prev => `${textContent}`?.length > 0 ? textContent : prev)
    }
  }, [clientRect, showChat, textContent, uncollapsedFiles]);

  return (
    <>
      <ReportTabDownloadReport updated={updated} />

      <main className='flex flex-row items-start gap-4 relative'>
        <div className='flex-1'>
          {Object.entries(report.codeQuality).map(([reportType, reportData]) => {
            return (
              <section key={reportType} className='flex flex-row items-start relative mb-4 gap-2'>
                <div 
                  className='flex-1 flex justify-start items-stretch gap-4 border border-custom-50 rounded-xl py-4' 
                  onClick={handleCloseChat}
                  onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                    if ((e.target as HTMLDivElement).id !== 'portal') {
                      const parentElement = (e.target as HTMLDivElement).closest('[id*="source"]');
                      const idParts = parentElement?.id.split('--');
                      const sourceId = idParts?.[idParts?.length - 1];

                      setSource(prev => parentElement && sourceId ? sourceId : prev)
                      setQuestionReportType(prev => reportType || prev)
                    }
                  }}
                >
                  {/* SCORE -START- */}
                  <div className='basis-[15%] flex flex-col items-center justify-start gap-2 shrink-0 pt-4'>
                    <img src={getScoreIcon(reportData.summary.score)} alt='score' />
                    <p>{getReportTypeNames(reportType)}</p>
                  </div>
                  {/* SCORE -END- */}

                  {/* CONTENT - START */}
                  <div className={`basis-[80%] flex flex-col items-start justify-between gap-5 text-sm text-custom-400`}>
                    {/* AUDIT TEXT - START */}
                    <div className='flex w-full text-base leading-7 gap-4 pr-2'>
                      <div className='basis-[10%] shrink-0'>Conclusion:</div>
                      <div className='basis-[90%] relative text-justify' id='source--conclusion'>
                        <ReactMarkdown 
                          components={highlightMarkdown({
                            explanations,
                            setShowChat,
                            setExplanationId,
                            explanationId,
                          })}>
                            {highlightText({
                              text: reportData.summary.conclusion,
                              reportType,
                              explanations,
                              source: 'conclusion',
                              newQuestionFields: {
                                reportType: questionReportType as string,
                                source: source as string,
                                textContent: text as string,
                              },
                              showingChat: showChat,
                            })}
                          </ReactMarkdown>
                      </div>
                    </div>
                    {/* AUDIT TEXT - END */}

                    <FilesChecked
                      setUncollapsedFiles={setUncollapsedFiles}
                      uncollapsedFiles={uncollapsedFiles}
                      report={report}
                      reportData={reportData}
                      reportType={reportType}
                      explanations={explanations}
                      explanationId={explanationId}
                      setExplanationId={setExplanationId}
                      setShowChat={setShowChat}
                      showChat={showChat}
                      source={source}
                      text={text}
                      questionReportType={questionReportType}
                    />
                  </div>
                  {/* CONTENT - END */}
                </div>
              </section>
            )
          })}
        </div>
      </main>

      <Portal>
        <>
          {
            getLineBadges(explanations, uncollapsedFiles).map((line, idx) => (
              <div
                key={idx} 
                style={{ top: `${line[0].top - 1}px`, right: `0px` }} 
                className={
                  'absolute w-[8vw] flex flex-row items-center gap-px'
                }
              >
                {line.map((badge, badgeIdx) => answerLoading ? (
                  <div
                    key={badgeIdx}
                    className={`w-5 h-5 rounded-full p-px relative hover:opacity-50 transition-opacity`}
                  >
                    <IconLoader 
                      className={'w-full h-full animate-spin'}
                    />
                  </div>
                ) : (
                  <button
                    key={badgeIdx}
                    onClick={() => {
                      setExplanationId(prev => {
                        if (prev === badge.id) {
                          setShowChat(false)
                          return undefined
                        }
                        setShowChat(true)
                        return badge.id
                      })
                    }} 
                    className={`w-5 h-5 rounded-full ${explanationId === badge.id ? 'bg-yellow-200' : 'bg-custom-50'} p-px relative hover:opacity-50 transition-opacity`}
                  >
                    <IconMessage 
                      className={'w-full h-full'}
                    />
                  </button>
                ))}
              </div>
          ))}

          {showChat  && (
            <div className='fixed bottom-2 right-2 w-[650px] h-[700px] max-h-[80vh] max-w-[80vw]'>
              <ChatModal
                onSend={handleSendMessage}
                explanation={explanations.find(({ id }) => id === explanationId)}
                onClose={handleCloseChat}
                textAreaValue={inputValue || ''}
                setTextAreaValue={setInputValue}
                answerLoading={answerLoading}
              />
            </div>
          )}
        </>
      </Portal>

      <Popover 
        setShowChat={value => {
          if (value) {
            handleOpenChat()
          } else {
            setShowChat(false)
          }
        }} 
        showChat={showChat} 
      />
    </>
  )
}

/* Return the total of badges (based on explanations) which should have on each line */
function getLineBadges(explanations: Explanation[], uncollapsedFiles: string[]) {
  const filtered = explanations.filter(explanation => {
    return explanation.source === 'conclusion' || uncollapsedFiles.some(file => file === `${explanation.report}${explanation.source}`)
  })
  return filtered.reduce((current, explanation) => {
    if (current[current.length - 1]?.[0]?.top !== explanation?.top) {
      current.push([explanation])
      return current
    }
    current[current.length - 1].push(explanation)
    return current;
  }, [] as Explanation[][])
}

/* Return the content with ** around highlighted content */
export function highlightText({ 
  text: _text, 
  reportType, 
  explanations, 
  newQuestionFields, 
  showingChat,
  source,
}: {
  text: string, 
  reportType: string, 
  explanations: Explanation[], 
  source: string,
  newQuestionFields: {
    reportType: string,
    source: string,
    textContent: string
  },
  showingChat: boolean,
}) {
  const formatTextContent = (content: string) => content?.replace(/`/g, '')?.replace(/\b\d+\.\s|<\d+>\./g, '')
  const text = formatTextContent(_text)

  const highlights = explanations
    .filter(explanation => explanation?.source === source &&  explanation?.report === reportType && explanation.text.length > 0)
    .map((explanation) => {
      const idx = formatTextContent(text).indexOf(explanation.text)
      return { 
        text: explanation.text, 
        source: explanation.source,
        idx,
      }
    })
    .filter(item => item.idx >= 0)

  /* This is to add the yellow before create the chat, so if the text is selected, it will be pushed to highlights  */
  if (`${newQuestionFields.textContent}`.trim().length > 0 && newQuestionFields.textContent && showingChat && (newQuestionFields?.reportType === reportType)) {
    const idx = formatTextContent(text).indexOf(newQuestionFields.textContent)

    if (idx  < 0 ) {
      return;
    }

    highlights.push({ 
      text: newQuestionFields.textContent, 
      source: newQuestionFields?.source as string,
      idx
    })
  }

  if (highlights.length === 0) return text;

  const highlightedText: string[] = [];
  let lastIndex = 0;

  highlights
    .sort((a: any,b: any) => a.idx - b.idx)
    .forEach(question => {
      const startIdx = question?.idx || 0;
      const endIdx = startIdx + (question.text as string).length
      const content = formatTextContent(text.slice(startIdx, endIdx))

      if (startIdx === endIdx) {
        return;
      }

      const el = ` *${content.trim()}* `

      if (el !== '**') {
        highlightedText.push(text.slice(lastIndex, startIdx), el);
        lastIndex = endIdx;
      }
    })


  highlightedText.push(text.slice(lastIndex));

  return highlightedText.join(``)
}


export function highlightMarkdown(props: {
  explanations: Explanation[]
  setShowChat: (value: boolean) => void
  setExplanationId: React.Dispatch<React.SetStateAction<string | undefined>>
  explanationId: string | undefined
}) {
  const { explanations, setShowChat, setExplanationId, explanationId } = props
  return {
    em(props: React.ClassAttributes<HTMLElement> & React.HTMLAttributes<HTMLElement> & ExtraProps) {
      const explanationID = explanations
        .filter((explanation) => explanation.text === props.children)?.[0]?.id

      return (
        <span
          className={`group ${explanationId === explanationID ? 'bg-yellow-100' : 'bg-yellow-100'} cursor-pointer transition-colors `}
          onMouseDown={() => {
            setShowChat(true)
            setExplanationId(prev => prev === explanationID ? undefined : explanationID)
          } }
        >
          {props.children}
        </span>
      )
    },
    strong: ({ children }: PropsWithChildren<any>) => <><b style={{ lineHeight: '60px' }}>{children}</b><br/></>
  }
} 