import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { IconCircle, IconCircleCheck } from '@tabler/icons-react'

export default function ConnectRepositoryRadioStep1() {
  const remotes = ['GitHub']
  const [selected, setSelected] = useState<string>(remotes[0])

  return (
    <div className="w-full px-4">
      <div className="mx-auto w-full max-w-md">
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only">Code management tool</RadioGroup.Label>
          <div className="space-y-2">
            {remotes.map((item, index) => (
              <RadioGroup.Option
                key={index}
                value={item}
                className={({ active, checked }) =>
                  `${
                    active
                      ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-custom-50'
                      : ''
                  }
                  ${checked ? 'bg-custom-900/75 text-white' : 'bg-white'}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? 'text-white' : 'text-custom-900'
                            }`}
                          >
                            {item}
                          </RadioGroup.Label>
                        </div>
                      </div>
                      {checked ? (
                        <div className="shrink-0 text-white">
                          <IconCircleCheck className="h-6 w-6" />
                        </div>
                        ) 
                        : 
                        <div className="shrink-0 text-custom-900">
                          <IconCircle className="h-6 w-6" />
                        </div>
                      }
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  )
}