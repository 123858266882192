import { IconBook2 } from '@tabler/icons-react';
import { useSelector } from 'react-redux';

interface Props {
  updated: Date;
}

export default function ReportTabDownloadReport(props: Props) {
  const { updated } = props;

  const currentRepo = useSelector((state: any) => state.user.currentRepo);

  const formattedUpdated = updated.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return (
    <div className='flex items-center justify-between border border-custom-50 rounded-xl p-3 mb-10'>
      <div className='flex gap-3 items-center'>
        <div className='bg-custom-50 rounded h-14 w-14 flex items-center justify-center'>
          <IconBook2 className='h-10 w-10' />
        </div>
        <div>
          <div className='font-semibold'>{currentRepo}</div>
          <div className='text-custom-400'>{formattedUpdated}</div>
        </div>
      </div>
      {/* <div 
        className="bg-custom-900 hover:bg-gray-800 text-white w-60 h-10 p-2 rounded-md mt-6 cursor-pointer flex gap-2 items-center justify-center" 
      > 
        <IconDownload />
        <span>Download Report</span>
      </div> */}
    </div>
  );
}