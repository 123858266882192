import { Navigate, Outlet } from "react-router-dom";

interface ProtectedRouteType {
  isAllowed: boolean,
  redirectPath?: string,
  children?: React.ReactElement
}

export const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/',
  children,
}: ProtectedRouteType) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};