import { IconBook2, IconFolderOpen, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { ProjectsRepoResponseType } from '../types/repositories';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { setCurrentRepo } from '../store/userSlice';
import { deleteRepo } from '../api/repositories';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';


interface Props {
  isConnected: boolean;
  repository: ProjectsRepoResponseType;
  setIsLanguageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsWarningModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: NavigateFunction;
  setSelectedRepository: React.Dispatch<React.SetStateAction<ProjectsRepoResponseType>>;
}

const RepositoryCard: React.FC<Props> = ({ isConnected, repository, setIsWarningModalOpen, navigate, setIsLanguageModalOpen, setSelectedRepository }) => {
  const { id, repoName, lastAuditDate, auditStatus, uncheckedAudits } = repository;
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contextMenuRef.current && !contextMenuRef.current.contains(event.target as Node)) {
        setIsContextMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div 
      ref={contextMenuRef}
      key={id}
      onClick={() => {
        setIsContextMenuOpen(false);
        dispatch(setCurrentRepo(repoName))
        navigate(`/code-audits-history/${id}`);
      }}
      className="relative w-64 h-80 rounded-2xl shadow-lg bg-white flex flex-col items-center justify-between border border-custom-50 overflow-visible cursor-pointer"
    >

            <div className="flex h-40 flex-col justify-start items-center bg-custom-100 p-3 w-full">
              <div className='flex justify-between items-center w-full'>
                <span className='bg-custom-300 p-1 px-2 rounded-full self-start text-xs text-custom-900 font-medium'>
                  {isConnected ? 'Connected' : 'Disconnected'}
                </span>
                {(!!uncheckedAudits && uncheckedAudits > 0) && <Tippy content={'Number of unchecked audits'}><div className='w-6 h-6 rounded-full p-1 bg-green-400 flex items-center justify-center cursor-default' >{uncheckedAudits}</div></Tippy>}
              </div>
              <div className="h-full flex items-center justify-center">
                <IconBook2 className='w-12 h-12 text-custom-900' />
              </div>
            </div>
      
      <h2 className="font-medium text-base mb-2 self-start pl-4 text-custom-900">{repoName}</h2>
      <p className='font-normal text-sm text-custom-400 self-start pl-4'>Last code audit: {lastAuditDate ? lastAuditDate.substring(0, 10) : '-' }</p>

      <div className='flex items-center justify-between w-full p-4'>
        <div 
          className={`${auditStatus !== "InProgress" ?  'bg-custom-900 hover:bg-gray-800 text-white cursor-pointer' : ' bg-custom-300 text-custom-900 cursor-default'} w-44 h-8 p-2 rounded-md text-center text-xs`}
          onClick={(event) => {
            event.stopPropagation();
            if (auditStatus !== "InProgress") {
              setIsLanguageModalOpen(true);
              setSelectedRepository({ id, repoName, lastAuditDate, auditStatus, uncheckedAudits });
            }
          }}
        > 
        {(auditStatus === "Failed" || auditStatus === "Completed" || !auditStatus) ? "Request Code Audit" : `Code Audit: ${auditStatus}`}
        </div>
        <div 
          onClick={(event) => {
            event.stopPropagation();
            setIsContextMenuOpen(!isContextMenuOpen)
          }}
          className="text-2xl cursor-pointer h-8 flex items-center justify-center"
        >
          ...
        </div>
      </div>
      {isContextMenuOpen && (
        <div className="absolute -bottom-24 right-0 bg-white shadow-lg rounded-md z-20">
          <div
            onClick={() => {
              setIsContextMenuOpen(false);
              dispatch(setCurrentRepo(repoName))
              navigate(`/code-audits-history/${id}`);
            }}
            className="text-custom-900 hover:bg-custom-100 px-4 py-2 rounded-md cursor-pointer flex items-center justify-start gap-2">
            <IconFolderOpen />
            <p>Code Audits History</p>
          </div>
          {/* <div className="text-custom-900 hover:bg-custom-100 px-4 py-2 rounded-md cursor-pointer flex items-center justify-start gap-2">
            <IconEdit />
            <p>Edit Repository</p>
          </div>
          <div className="text-custom-900 hover:bg-custom-100 px-4 py-2 rounded-md cursor-pointer flex items-center justify-start gap-2">
            <IconPlugConnectedX />
            <p>Disconnect Repository</p>
          </div> */}
          <div 
            className="text-custom-900 hover:bg-custom-100 px-4 py-2 rounded-md cursor-pointer flex items-center justify-start gap-2"
            onClick={(event) => {
              event.stopPropagation();
              setIsContextMenuOpen(false);
              deleteRepo({ id })
                .then((res) => {
                  setSelectedRepository({ id, repoName: repoName, lastAuditDate, auditStatus, uncheckedAudits });
                  console.log(res, 'res');
                })
                .catch((err) => {
                  console.log(err, 'err');
                });
            }}
          >
            <IconTrash />
            <p>Remove Repository</p>
          </div>
        </div>
      )}
    </div>
  );
};


export default RepositoryCard;
