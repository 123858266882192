import { AxiosResponse } from 'axios';
import { instance } from './index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserType } from '../types/users';

const controller = new AbortController();

export const putUser = createAsyncThunk(
  'users/updateUser',
  async (data: UserType, { signal }) => {
    signal.addEventListener('abort', () => {
      controller.abort();
    });
      const response: AxiosResponse = await instance.put('/users', data);
      return response.data;
  }
);

export const getMe = createAsyncThunk(
  'users/getMe',
  async (_data, { signal }) => {
    signal.addEventListener('abort', () => {
      controller.abort();
    });
      const response: AxiosResponse = await instance.get('/users/me');
      return response.data;
  }
);
