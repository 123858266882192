import React from "react";

interface Props {
  userName: string;
}

export const getInitials = (name: string) => {
    const nameArray = name.split(' ');
    if (nameArray.length > 1) {
      return nameArray[0][0]?.toUpperCase() + nameArray[1][0]?.toUpperCase();
    } 
    return name[0]?.toUpperCase() + name[1]?.toUpperCase();
 
};

const UserAvatar = (props: Props) => {
  const { userName } = props;
  
  return (
    <>
      <div className="shrink-0 w-12 h-12 flex items-center justify-center rounded-full bg-custom-50">
        {userName && getInitials(userName)}
      </div>
      {userName &&
      <div className="hidden sm:block text-base font-medium truncate">
        {userName}
      </div>
      }
    </>
    
  );
};

export default UserAvatar;