import { Transition, Dialog } from "@headlessui/react";
import { IconX } from "@tabler/icons-react";
import { Fragment } from "react";
import ConnectRepositoryRadioStep1 from "../ConnectRepositoryRadioStep1";

interface Props {
  setIsOpenStep1: (isOpenStep1: boolean) => void;
  isOpenStep1: boolean;
}

const ConnectRepositoryOverlayStep1 = (props: Props) => {
    const { setIsOpenStep1, isOpenStep1 } = props;

    return (
      <Transition.Root show={isOpenStep1} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={() => setIsOpenStep1(false)}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-black/40" />

          <div className="fixed w-5/6 sm:w-1/2 xl:w-1/4 inset-y-0 right-0 max-w-full flex pl-8">
            <Transition.Child
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <div className="w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 flex z-20">
                    <button
                      type="button"
                      className="text-custom-400"
                      onClick={() => setIsOpenStep1(false)}
                    >
                      <IconX className="h-8 w-8" />
                    </button>
                  </div>
                </Transition.Child>
                <div id="styled-scroll" className="grow flex flex-col h-full bg-white shadow-xl overflow-y-auto rounded-l-2xl">
                  <div className='grow flex gap-8 flex-col justify-start items-center h-full rounded bg-white' onClick={e => e.stopPropagation()}>
                    <div className="font-semibold text-lg m-2 self-start">1/2</div>
                    <div className="font-semibold text-2xl">Connect repository</div>
                    <div className="px-4">Select a source code management tool.</div>
                    <ConnectRepositoryRadioStep1 />
                  </div>
                  <div className="p-10 border-t border-custom-50 flex justify-center items-center flex-wrap gap-6">
                    <div 
                      className="bg-white hover:bg-white text-custom-900 w-40 h-10 p-2 rounded-md text-center cursor-pointer border border-custom-50"
                      onClick={() => setIsOpenStep1(false)}
                    > 
                      Cancel
                    </div>
                    <a 
                      // href="https://github.com/login/oauth/authorize?client_id=f37c6e0a6c231afe7737&scope=repo&allow_signup=repo" // My client_id
                      href="https://github.com/login/oauth/authorize?client_id=50b002a9d6ecfca0aa09&scope=repo&allow_signup=repo" // Miguel's client_id
                      className="bg-custom-900 hover:bg-gray-800 text-white w-40 h-10 p-2 rounded-md text-center cursor-pointer"
                    > 
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    );
  };
  
  export default ConnectRepositoryOverlayStep1;